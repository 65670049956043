// export const BASE_URL = '192.168.0.200:8000'
// export const DATA = '192.168.0.180:8000'
// export const BASE_URL = 'http://192.168.0.169:8000';
// export const GQL_URL = 'http://192.168.0.169:8000/graphql'

// export const BASE_URL = "https://api.cvdmart.com";
// export const GQL_URL = "https://api.cvdmart.com/graphql";
// export const WEB_SOCKET_URL = "wss://api.cvdmart.com/graphql";

export const BASE_URL = "https://api.cvdmart.com";
export const GQL_URL = "https://api.cvdmart.com/graphql";
export const WEB_SOCKET_URL = "wss://api.cvdmart.com/graphql";
