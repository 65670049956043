// ** Logo
// import logo from '@src/assets/images/logo/logo.png'
import logo from "../../../assets/images/pages/header-logo.png"

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader'>
      {/* <div style={{width :'200px'}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 23.52" style={{ fill: '#6c7633' }}><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="
          " d="M4.68,23.37a10.89,10.89,0,0,1-3.43-4.58A16.71,16.71,0,0,1,0,12.35,15.64,15.64,0,0,1,2.42,3.72,7.54,7.54,0,0,1,8.55,0a7.54,7.54,0,0,1,6.13,3.72,15.64,15.64,0,0,1,2.42,8.63,16.53,16.53,0,0,1-1.25,6.44,10.66,10.66,0,0,1-3.44,4.59.7.7,0,0,1-1-.16.72.72,0,0,1-.11-.59,10.48,10.48,0,0,0,.5-2.77,5.88,5.88,0,0,0-.25-2.17,4.21,4.21,0,0,0-1.3-1.81,2.63,2.63,0,0,0-3.47,0,4,4,0,0,0-1.26,1.8,8,8,0,0,0,.09,4.15c.06.31.12.62.17.9a.71.71,0,0,1-.59.79.69.69,0,0,1-.52-.15Zm-3.31-11a15.91,15.91,0,0,0,.56,4.23,11,11,0,0,1,.23-2.75A10.05,10.05,0,0,1,4.53,9.09a5.55,5.55,0,0,1,4-2,5.57,5.57,0,0,1,4,2,10,10,0,0,1,2.36,4.74h0a10.86,10.86,0,0,1,.23,2.79,16.23,16.23,0,0,0,.57-4.28,14.2,14.2,0,0,0-2.19-7.86,6.19,6.19,0,0,0-5-3.12,6.19,6.19,0,0,0-5,3.12,14.2,14.2,0,0,0-2.19,7.86Zm2.26,6.07c.08.27.18.54.29.81a5.77,5.77,0,0,1,.33-2A5.38,5.38,0,0,1,6,14.8a4,4,0,0,1,5.18,0,5.61,5.61,0,0,1,1.72,2.41,6.57,6.57,0,0,1,.34,2c.1-.26.19-.51.27-.76a8.67,8.67,0,0,0,.13-4.32h0a8.6,8.6,0,0,0-2-4.08,4.2,4.2,0,0,0-3-1.51,4.18,4.18,0,0,0-3,1.51,8.8,8.8,0,0,0-2,4.09,8.46,8.46,0,0,0,.13,4.31Z" /></g></g></svg>
      </div> */}
      <img className='fallback-logo' src={logo} alt='logo' width={110} />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
